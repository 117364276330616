import { createTheme, responsiveFontSizes, ThemeOptions } from '@mui/material/styles';

const primaty_main = '#454a96';

const light: ThemeOptions = {
  palette: {
    primary: {
      main: primaty_main,
    },
    secondary: {
      main: '#607070',
    },
    background: {
      default: '#ccc',
      paper: 'white',
    },
    text: {
      primary: '#111',
    },
  },
  typography: {
    fontSize: 14,
    fontFamily: ['Noto Sans JP', 'Roboto', 'sans-serif'].join(','),

    h1: {
      fontSize: '26px',
      fontWeight: 'bold',
      marginTop: '28px',
      lineHeight: 1.4,
    },
    h2: {
      fontSize: '20px',
      fontWeight: 'bold',
      marginTop: '28px',
      marginBottom: '20px',
      paddingTop: '9px',
      paddingBottom: '9px',
      paddingLeft: '7px',
      marginLeft: '-14px',
      borderLeft: 'solid 7px black',
      background: primaty_main,
      color: 'white',
    },
    h3: {
      fontSize: '20',
      fontWeight: 'bold',
      marginTop: '28px',
      marginBottom: '20px',
      paddingBottom: '7px',
      borderBottomStyle: 'solid',
      borderBottomWidth: '3px',
      borderBottomColor: primaty_main,
    },
    h4: {
      fontSize: '20',
      fontWeight: 'bold',
    },
    body1: {
      lineHeight: 1.8,
      marginTop: '12px',
      marginBottom: '12px',
    },
  },
};

// ダークテーマを定義
let dark = JSON.parse(JSON.stringify(light)) as ThemeOptions;
dark.palette!.mode = 'dark';
dark.palette!.background!.default = '#1a1a1a';
dark.palette!.background!.paper = '#303030';
dark.palette!.text!.primary = '#eee';

export const lightTheme = responsiveFontSizes(createTheme(light), {});

export const darkTheme = responsiveFontSizes(createTheme(dark), {});
