import React, { ReactNode, useEffect } from 'react';
import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import { useStore } from './store-provider';

import { lightTheme, darkTheme } from '../../styles/theme';
import '../../styles/style.css';
import { Helmet } from 'react-helmet';

const StyleProvider = (props: { children: ReactNode }) => {
  // // ダークモードの環境設定を取得

  const { state, dispatch } = useStore();

  useEffect(() => {
    if (state.isDarkMode == null) {
      const localStrageIsDarkMode = localStorage.getItem('isDarkMode') ?? undefined;
      if (localStrageIsDarkMode != null) {
        dispatch({ type: 'set_dark_mode', isDarkMode: localStrageIsDarkMode === 'true' });
      } else {
        const isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
        dispatch({ type: 'set_dark_mode', isDarkMode: isDarkMode });
      }
    }
  });

  return (
    <>
      <Helmet>
        {
          // ダークモードかどうかで prism のスタイル変更
          // state.isDarkMode ? <link rel="stylesheet" href="/prism-themes/prism-twilight.css" /> : <link rel="stylesheet" href="/prism-themes/prism-coy.css" />
          <link rel="stylesheet" href="/prism-themes/prism-coy.css" />
        }
      </Helmet>
      <ThemeProvider theme={state.isDarkMode === true ? darkTheme : lightTheme}>
        <CssBaseline />
        <Box
          sx={{
            minHeight: '100vh',
            // ダークモードであれば記事内のリンク色変更
            '& .article a': {
              color: state.isDarkMode ? '#bbf' : undefined,
            },
          }}
        >
          {props.children}
        </Box>
      </ThemeProvider>
    </>
  );
};

export default StyleProvider;
