import * as React from 'react';
import MainProvider from './src/components/providers/main-provider';
import favicon from './static/favicon.ico';
import { Helmet } from 'react-helmet';

export const wrapRootElement = ({ element }) => {
  return (
    <>
      <Helmet>
        <link rel="icon" href={favicon} type="image/x-icon" />
      </Helmet>
      <MainProvider>{element}</MainProvider>
    </>
  );
};
