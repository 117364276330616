import React, { ReactNode } from 'react';
import StoreProvider from './store-provider';
import StyleProvider from './style-provider';

const MainProvider = (props: { children: ReactNode }) => {
  return (
    <StoreProvider>
      <StyleProvider>{props.children}</StyleProvider>
    </StoreProvider>
  );
};

export default MainProvider;
