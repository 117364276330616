import React, { createContext, ReactNode, useContext, useReducer } from 'react';

// グローバルステート
const initialState = {
  // ダークモード
  isDarkMode: undefined as boolean | undefined,
};
type State = typeof initialState;

// アクション
type Action =
  | {
      type: 'set_dark_mode';
      isDarkMode: boolean;
    }
  | {
      type: 'other_actions';
    };

// reducer 定義
export const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'set_dark_mode': {
      return { ...state, isDarkMode: action.isDarkMode };
    }
    default: {
      return state;
    }
  }
};

const Context = createContext(
  {} as {
    state: State;
    dispatch: React.Dispatch<Action>;
  }
);

const StoreProvider = (props: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <>
      <Context.Provider value={{ state, dispatch }}>{props.children}</Context.Provider>
    </>
  );
};
export default StoreProvider;

export const useStore = () => useContext(Context);
